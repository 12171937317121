@import "../styles/base.scss";

.container {
  @include make-row(0);
  padding: 0;
}

.maxWidthContainer {
  max-width: $app-page-max-width;
  margin: auto;
}

.title {
  color: $primary;
  margin-bottom: 0;
}
