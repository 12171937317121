@import "../../styles/base.scss";

.header {
  height: $header-height;
  border-bottom: 1px solid rgba($secondary, 0.4);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: $spacer * 0.5 0;
  @include media-breakpoint-up(sm) {
    padding: 0 0 0 $spacer * 1;
  }
}

.mainSection {
  max-width: 100%;
}

.logo {
  height: $header-logo-height;
  @include media-breakpoint-down(xs) {
    margin: $spacer / 4 $spacer / 2;
  }
}

.container {
  @include make-container(0);
}

.titleContainer {
  flex: 1;
  text-align: center;
}
