.prev {
  left: 50px;
}

.next {
  right: 50px;
}

.nav {
  opacity: 0;
  color: white;
}

.nav:focus {
  outline: none;
}

.nav:after {
  background: rgba(12,12,12,0.3);
  padding: 50px;
}
