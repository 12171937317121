@import "../../styles/base.scss";

.styledName {
  color: $primary;
  white-space: nowrap;
  position: relative;
  z-index: 1;
}

.styledNameBackground {
  padding: 0 10px;
}

.styledNameBackground:after {
  content: "";
  background-color: adjust-color($secondary, $lightness: -10%);
  position: absolute;
  width: calc(100% - 10px);
  height: 100%;
  right: 5px;
  top: 0;
  z-index: -1;
  transform: skew(-10deg);
}
