@import "../../styles/base.scss";

.logo {
  max-width: 800px;
  min-width: 250px;
  margin: 0 $spacer;
}

.logoContainer {
  margin-top: $spacer * 2;
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(xxl) {
    margin-top: $spacer * 5;
  }
}

.maxWidthContainer {
  max-width: $landing-page-max-width;
  margin: auto;
}

.heroText,
.heroText2 {
  text-align: center;
  font-family: $headings-font-family;
  padding: $spacer * 2;
  @include media-breakpoint-up(md) {
    padding: $spacer * 3;
  }
}

.heroText {
  @include font-size($h3-font-size);
  color: $headings-color;
  line-height: $headings-line-height;
}

.heroText2 {
  @include box-shadow($box-shadow);
  @include font-size($h5-font-size);
  background-color: $secondary;
  color: $gray-100;
  line-height: $headings-line-height * 1.5;
  margin-bottom: $spacer * 4;
}

.row {
  @include make-row(0);
  max-width: $landing-page-max-width;
  margin: auto auto $spacer * 3;
  padding-bottom: $spacer * 3;
  border-bottom: $border-color 1px solid;
}

.rowTitle {
  @include font-size($h4-font-size);
  font-family: $headings-font-family;
  line-height: $headings-line-height;
  font-weight: $headings-font-weight;
  margin-bottom: $spacer;
  flex: 100%;
  text-align: center;
}

.footerItem {
  @include make-col-ready();
  display: flex;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
}

.footerItemLink {
  color: $text-muted;
}

.footerItemImg {
  max-height: 44px;
  object-fit: contain;
  margin: $spacer 0;
}
