.slideContainer{
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  overflow: hidden;
}

.slideDescription{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.slideDescription > * {
  padding-right: 20px;
  padding-left: 20px;
}

.image {
  margin: auto;
  min-width: 50vw;
  max-height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.qrContainer{
  position: absolute;
  bottom: 0;
  right: 0px;
  padding: 10px;
  background-color: white;
}

.title {
  text-align: center;
  font-size: var(--kiosk-title-font-size);
}

.subTitle {
  margin: 0;
  font-size: var(--kiosk-subtitle-font-size);
}

.descriptionTxt{
  white-space: pre-wrap;
  font-size: var(--kiosk-facts-font-size);
  overflow: hidden;
}

@media all and (orientation:portrait) {
  .slideContainer{
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.iframe {
  border: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

.vertical .slideContainer{
  flex-direction: column;
  justify-content: space-evenly;
}

.vertical .slideContainer .image{
  margin-top: unset;
  margin-bottom: unset;
}

.img-20-vh .slideContainer .image{
  max-height: 20vh;
}

.video {
  display: block;
  width: 100%;
}

.spaced .slideContainer{
  padding: 100px;
}
