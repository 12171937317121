$header-height: 50px;
$header-logo-height: $header-height - 10px;

$landing-page-max-width: 1200px;
$app-page-max-width: 750px;

$enable-shadows: true;

$black: #1f201a;
$primary: #22cd5e;
$secondary: #2291cd;
$red-lighten-1: #ef5350;

$link-decoration: underline;

$font-family-sans-serif: 'Recursive', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1350px
);


$app-datebutton-boxshadow: 0px 2px 2px gray;
$app-datebutton-maxdimension: 80px;
$app-datebutton-background-color: #eeeeee;
$app-datebutton-arrow-color: $secondary;

$app-datebutton-today-color: $red-lighten-1;
$app-datebutton-active-color: $primary;

$app-eventlistitem-background-color: $secondary;
